.table-container {
  max-height: 70vh;
}

.table-header-cell {
  background-color: #2d6fa1 !important;
  color: white !important;
}

.table-actions-button {
  margin: 0 4px;
  color: #2d6fa1;
}

.table-cell-small-device {
  padding: 8px;
}
