.forgot-password-paper {
  margin-top: 8 * 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.forgot-password-avatar {
  margin: 8px;
  width: 100px;
  height: 100px;
}

.forgot-password-form {
  width: 100%;
  margin-top: 8px;
}

.forgot-password-submit {
  margin: 3 * 8px 0 2 * 8px;
}

.forgot-password-success {
  width: 100%;
  margin-top: 16px;
}
