.width80 {
  width: 80%;
}

.section {
  background-color: white;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 0px 1px 2px #8e8e8e;
}

ul {
  text-decoration: none;
  list-style-type: none;
}

ul li:nth-last-child(1) {
  border: none;
}

.line {
  padding: 20px 5px 5px 15px;
  position: relative;
  border-bottom: 1px solid lightgrey;
  margin: 10px 0px;
}

.line .label {
  position: absolute;
  top: 0;
  left: 5px;
  font-size: 0.9rem;
  color: lightslategray;
}

.line .value {
  position: relative;
  margin-top: 150px;
  font-size: 1.25rem;
}

.booline {
  padding: 5px 5px 5px 5px;
  position: relative;
  border-bottom: 1px solid lightgrey;
  margin: 10px 0px;
}

.booline .boolean {
  max-width: 50%;
  padding: 5px;
  display: flex;
  justify-content: left;
  align-items: center;
  color: #2d6fa1;
  text-transform: uppercase;
  font-size: 1rem;
}

.booline .boolean span {
  margin-left: 10px;
}

.objectLine {
  position: relative;
  margin: 10px 0px;
}

.objectLine .title {
  background-color: lightgray;
  padding: 5px 5px 5px 10px;
  text-transform: uppercase;
  font-size: 0.9rem;
  color: lightslategray;
}

.objectLine ul {
  padding-left: 20px;
}

.arrayline {
  padding: 20px 5px 5px 15px;
  position: relative;
  border-bottom: 1px solid lightgrey;
  margin: 10px 0px;
}

.arrayline .label {
  position: absolute;
  top: 0;
  left: 5px;
  font-size: 0.9rem;
  color: lightslategray;
}

.arrayline .value {
  position: relative;
  font-size: 1.25rem;
  display: flex;
  justify-content: left;
  align-items: center;
}

.arrayline .value .item {
  padding: 5px;
  background-color: #e5e5e5;
  border-radius: 5px;
  margin: 5px;
}

.title-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
}

.title-div .title-info {
  color: #d17348;
}
