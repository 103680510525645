.set-password-paper {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.set-password-logo {
  margin: 40px;
  width: 300;
  height: auto;
}

.set-password-form {
  width: 100%;
  margin-top: 8px;
}

.set-password-button {
  margin: 3 * 8px 0 2 * 8px;
}
