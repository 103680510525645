.tab-app-container {
  flex-grow: 1;
  background-color: #f8f8f8;
  min-height: 100vh;
  padding: 0;
}

.tab-app-background {
  background-color: #f8f8f8;
}

.tab-app-panel {
  transition: all 250ms ease-in-out 100ms;
  opacity: 1;
}

.lock-left {
  opacity: 0;
  transform: translateX(-100%);
}

.lock-right {
  opacity: 0;
  transform: translateX(100%);
}
