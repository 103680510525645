:root {
  --active-background: #e0e0e0;
  --inactive-background: #ffffff;
}

.profil-matching-menu {
  background-color: var(--inactive-background);
  padding: 10px;
  cursor: pointer;
}

.profil-matching-menu:hover {
  background-color: #f5f5f5;
}

.profil-matching-menu-active {
  background-color: var(--active-background);
  border-radius: 10px 0 0 10px;
  position: relative;
}

.profil-matching-menu-active:hover {
  background-color: var(--active-background);
}

.profil-matching-menu-active::before {
  content: '';
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  z-index: 5;
  border-bottom-right-radius: 100%;
  border-width: 10px;
  border-color: var(--active-background);
  border-style: solid;
  border-top: none;
  border-left: none;
}

.profil-matching-menu-active::after {
  content: '';
  position: absolute;
  bottom: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  z-index: 5;
  border-top-right-radius: 100%;
  border-width: 10px;
  border-color: var(--active-background);
  border-style: solid;
  border-bottom: none;
  border-left: none;
}

.profil-matching-items {
  min-height: 80vh;
  background-color: var(--active-background);
  border-radius: 10px;
}
