.rolling-button-container {
  background-color: white;
  border: 1px solid black;
  padding: 8px 0px;
  border-radius: 5px;
  position: relative;
  text-overflow: ellipsis;
  margin: 12px 0px;
  text-align: center;
  width: 150px;
  height: 40px;
  cursor: pointer;
}

.rolling-button-label {
  position: absolute;
  top: 0;
  left: 12px;
  transform: translateY(-55%);
  background-color: inherit;
  padding: 4px;
  color: gray;
  border-radius: 5px;
}
.rolling-button-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  transition: all 200ms ease-in-out;
}

.lock-top {
  opacity: 0;
  transform: translate(-50%, -150%);
}

.lock-bottom {
  opacity: 0;
  transform: translate(-50%, 50%);
}
