.stats-timeline-selector {
  margin-top: 3% !important;
  margin-bottom: 0 !important;
  margin-left: 2% !important;
  width: 10%;
}

.stats-timeline-button {
  margin-top: 3% !important;
  margin-left: 2% !important;
}
