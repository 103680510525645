.react-calendar-timeline .rct-dateHeader-primary {
  background-color: #2d6fa1;
}

.react-calendar-timeline .rct-header-root {
  background-color: #2d6fa1;
}

.react-calendar-timeline .rct-outer {
  width: 100%;
}

.baner {
  background-color: #2d6fa1;
  padding: 10px 20px;
  color: white;
  border-radius: 0px;
}

.calendar-div {
  margin-top: 10px;
}
.calendar-div .calendar-title {
  margin: 20px;
}

.sticky {
  position: sticky;
  top: 65px;
  z-index: 200;
}

.custom-selector-container {
  margin-top: 16px;
}

.timeline-header {
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  background-color: #2d6fa1;
  color: white;
  border-bottom: 2px solid #d17348;
}

.rct-item {
  border-radius: 5px;
  text-align: center;
  overflow: hidden;
}

.rct-item .timeline-item-note {
  height: 10px;
  width: 10px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
}

.select-matching {
  margin-top: 16px;
}

.item-spliter {
  width: 100%;
  height: 10px;
  background-color: #2d6fa1;
  border-bottom: #d17348 1px solid;
  opacity: 0.7;
}
