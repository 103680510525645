.dragger {
  width: 300px;
  height: 250px;
  padding: 5px;
  background-color: white;
  transition: all 0.1s ease-in-out;
  border-radius: 5px;
  box-shadow: 1px black;
  margin-bottom: 10px;
  transition: all 0.5s ease-in-out;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dragger:hover {
  background-color: lightgray;
}
.dropper {
  min-width: 300px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 10px;
  width: auto;
  padding: 2px;
  height: 65vh;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.dropper::-webkit-scrollbar {
  display: none;
}

.item-no-display {
  display: none;
}

.draggerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1px;
  width: 90%;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.5rem;
  color: gray;
}

.draggerContent .dragger-content {
  text-align: center;
  padding: 3px;
}

.draggerContent .dragger-title {
  text-align: center;
  margin-top: 15px;
  padding: 3px;
  width: 50%;
}

.draggerContent .dragger-title h5 {
  color: #2d6fa1;
}

.draggerIcon {
  position: absolute;
  top: 0;
  right: 0;
}

.dragging {
  background-color: lightgray;
}
.dropOver {
  background-color: lightgray;
}

.marginTop {
  margin-top: 15px;
}

.maxWidth30 {
  max-width: 30%;
}

.itemError {
  border: 1px solid red;
  color: red;
}

.roundcolor {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.bg-red {
  background-color: red;
}

.bg-orange {
  background-color: orange;
}

.bg-green {
  background-color: green;
}

.bg-blue {
  background-color: darkblue;
}

.bg-grey {
  background-color: grey;
}

.bg-darkgrey {
  background-color: darkgrey;
}

.disabled {
  filter: grayscale(80%);
}

.icon-selector-item {
  cursor: pointer;
  border: 2px solid grey;
  padding: 5px 20px;
  border-radius: 5px;
  color: darkgrey;
}

.icon-selected {
  border: 2px solid green;
  position: relative;
  color: grey;
}

.icon-selected::before {
  content: '';
  position: absolute;
  top: 3px;
  right: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: green;
}

.left-icon {
  position: absolute;
  top: -30px;
  left: -20px;
}

.right-icon {
  position: absolute;
  top: -30px;
  right: -20px;
}

.width60 {
  width: 60%;
}
.width30 {
  width: 350px;
}

.warning-common-ai {
  position: absolute;
  width: 300px;
  top: -20px;
  left: 8%;
}

.card-match-icon {
  min-height: 50px;
  height: 50px;
  min-width: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #f5f5f5;
  margin: 20px 0px;
  box-shadow: 0 0 0 5px grey, inset 0 0 20px 5px #c1c1c1;
}

.course-bg-header {
  height: 50px;
  color: white;
}

.course-bg {
  background-color: #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
}

.selector-60per {
  width: 60%;
}
