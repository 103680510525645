.green {
  background-color: green;
}

.red {
  background-color: red;
}

.grey {
  background-color: lightgray;
}

.status {
  font-weight: bold;
  max-width: 100px;
  text-align: center;
  border-radius: 2px;
  color: white;
  text-transform: uppercase;
}

.leftButton {
  margin-right: 10px;
}

.buttonContainer {
  min-width: 360px;
  display: flex;
  justify-content: space-between;
}

.entities-list-form .table-container {
  max-height: 200vh;
}
