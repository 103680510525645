.workshop-available {
  margin-bottom: 10px;
  padding: 10px;
}

.workshop-available-content {
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 15px;
}

.workshop-available-bubble {
  background-color: lightgray;
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 0.9rem;
  opacity: 0.8;
  margin: 0px 5px;
}

.popup {
  min-height: 60vh;
  min-width: 40vw;
}

.divButton {
  min-width: 10vw;
  #Button {
    margin: 5px;
  }
}
