.width80 {
  width: 80%;
}

.section {
  background-color: white;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 10px !important;
}

.info-title {
  text-align: center;
  margin-bottom: 20px;
}

.section .info {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 0px;
}
.section .info-label {
  font-size: 1rem;
}
.section .info-text {
  font-size: 1rem;
  color: #6d6d6d;
}
.section .info-divider {
  height: 1px;
  width: 100%;
  background-color: rgb(163, 157, 157);
  margin: 10px 0px;
}
.info-img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-color: #f5f5f5;
  margin: 20px 0px;
  box-shadow: 0 0 0 5px grey, inset 0 0 20px 5px #c1c1c1;
}
.info-resume {
  text-align: center;
}
.info-resume .title {
  font-size: 2rem;
  font-weight: 500;
}
.info-resume .subtitle {
  font-size: 1.2rem;
  font-weight: 300;
  color: grey;
  margin: 15px 0px;
}
.info-resume .info-buttons {
  margin-bottom: 20px;
}

.info-title {
  color: #d17348;
}
