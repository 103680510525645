.stats-general-selector {
  margin: 1% !important;
  width: 30%;
}

.stats-general-section {
  max-width: 50%;
}

.stats-general-card {
  padding: 20px !important;
  margin: 2% 0 !important;
}

.stats-general-target {
  max-height: 500px !important;
  min-width: 300px !important;
  overflow-y: scroll;
}

.stats-general-title {
  margin-bottom: 20px !important;
}

.stats-general-subtitle {
  margin-left: 30px !important;
}

.stats-general-button {
  margin: 2% !important;
  max-height: 50px;
}

.stats-card {
  margin-top: 20px !important;
}

.padding-left {
  padding-left: 20px;
  margin-bottom: 10px;
}

.padding-all {
  padding: 5px 10px;
}

.stats-table {
  border: 1px solid black;
  border-collapse: collapse;
  width: 750px;
  margin: 20px 0px;
}

.stats-table th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
}

.stats-table tr:nth-child(even) {
  background-color: #f2f2f2;
}
