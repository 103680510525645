.multiple-select-container {
  width: 100%;
  min-width: 120px;
}

.multiple-select-container-max-width {
  max-width: 250px;
}

.multiple-select-render-value {
  margin-left: 4px;
}

.background-white {
  background-color: white;
  padding: 0 10px;
}
