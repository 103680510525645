.login-paper {
  margin-top: 8 * 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-avatar {
  margin: 8px;
  width: 100px;
  height: 100px;
}

.login-form {
  width: 100%;
  margin-top: 8px;
}

.login-submit {
  margin: 3 * 8px 0 2 * 8px;
}

.login-forgot-password-link {
  color: #d17348;
  text-decoration: none;
}

.login-forgot-password-link:hover {
  text-decoration: underline;
}
