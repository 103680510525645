.selector-container {
  width: 100%;
  margin: 16px 0 !important;
}

.selector-item-icon,
.selector-item-label {
  margin: 0 4px !important;
  vertical-align: middle;
}

.selector-input-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: white;
  padding: 0 5px;
}
