* {
  margin: 0;
  padding: 0;
}
.redText {
  color: red;
}
.cards {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
}

.card {
  width: 300px;
  height: 30vh;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 1px 3px #8e8e8e;
  margin: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.card:hover {
  transform: scale(1.1);
  box-shadow: 5px 5px 10px 0px rgb(105, 105, 105);
}

.card:hover .card-text {
  color: #2d6fa1;
}

.card-img {
  position: relative;
  width: 100%;
  height: 20vh;
  background-color: #d17348;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-img .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  height: 15vh;
  width: 15vh;
  border-radius: 50%;
}

.card-img .icon {
  color: #d17348;
  font-size: 12vh;
  z-index: 2;
}

.card-img .add {
  color: #2d6fa1;
  font-size: 5vh;
  position: absolute;
  top: 2vh;
  right: 30%;
  z-index: 3;
}

.card-text {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
