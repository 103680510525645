.spacer {
  flex: 1 1 10%;
}

.spacer-small {
  flex: 0 1 2%;
}

.filters {
  display: contents;
}

.table-rows-selected {
  width: 10%;
}
