@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
}

.app {
  display: flex;
  position: relative;
}

.marginT20 {
  margin-top: 20px;
}

.marginB20 {
  margin-bottom: 20px !important;
}

.come-in-top {
  -webkit-animation: slideInFromTop 0.3s ease-out 0s 1;
  animation: slideInFromTop 0.3s ease-out 0s 1;
}

.padding-small {
  padding: 8px;
}

.position-relative {
  position: relative;
}

.jss74 {
  align-items: center;
}

.text-centered {
  text-align: center;
}

.topleft20,
.topright20 {
  position: absolute;
  top: 0;
}

.topright20 {
  right: 40px;
}

.full-width {
  width: 100%;
}

.come-in-bottom {
  -webkit-animation: slideInFromBottom 0.3s ease-out 0s 1;
  animation: slideInFromBottom 0.3s ease-out 0s 1;
}

.margin-auto {
  margin: auto;
}

.topleft20,
.topright20 {
  position: absolute;
  top: 0;
}

.topright20 {
  right: 40px;
}

.margin20 {
  margin: 20px;
}

.come-in-left {
  -webkit-animation: slideInFromLeft 0.3s ease-out 0s 1;
  animation: slideInFromLeft 0.3s ease-out 0s 1;
}

.come-in-bottom-1s {
  -webkit-animation: slideInFromBottom 0.3s ease-out 0.3s 1 forwards;
  animation: slideInFromBottom 0.3s ease-out 0.3s 1 forwards;
}

.padding-normal {
  padding: 20px;
}

.bg-secondary-color {
  background-color: #2d6fa1;
}

.bg-grey-color {
  background-color: #6d6d6d;
}

.marginL20 {
  margin-left: 20px;
}

.color-red {
  color: #cd5c5c;
}
